.gallery-item {
  text-align: left;
  text-transform: none;

  font-family: Roboto;
}

.image {
  max-height:100%;
  max-width:100%;

  cursor: pointer;
}

.title {
    text-decoration: none;
    color: #ffffff;
    font-weight: 300;
    font-size: 14px;
    margin: 10px 0 10px 0;
}

.details {
    text-decoration: none;
    font-weight: 400;
    font-size: 14px;
    color: #969ba1;
    margin: 0 0 10px 0;
}
