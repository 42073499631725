.slides {
  position: relative;
  height: 100vw;
}

.slides > .slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  transition: all 1s;
  opacity: 0;
  object-fit: cover;
}

.slides > .active {
  visibility: visible;
  transition: all 1s;
  opacity: 1;
}

@media only screen and (min-width: 1024px) {

  .slides {
    width: 100%;
    height: 100%;
    padding-bottom: 75%;
  }

  .slides > .slide {
    height: 610px;
    object-fit: cover;
  }
}
