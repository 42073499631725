.home {
  border-bottom: 2px solid #ffffff;
  padding-bottom: 20px;
}

.bio {
  text-align: center;
}

.gallery-button {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.newsletter-button {
  margin-top: 20px;
  text-align: center;
}
