.header {
  margin: 0 auto;
  text-align: center;
  font-family: Roboto;
  // border-bottom: 2px solid rgba(0, 0, 0, .05);
  border-bottom: 2px solid #ffffff;
}

.title {
  height: 74px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-top: 10px;
}

.title>a {
  text-decoration: none;
}

.logo {
  color: #fff;
  font-weight: 300;
  font-size: 44px;
  letter-spacing: 6px;

  /* overlap */
  line-height: 0;
  margin: 0;

  animation: glitch000 2.5s infinite;
}

h1:nth-child(2) {
  color: #67f3da;
  animation: glitch001 2.5s infinite;
}

h1:nth-child(3) {
  color: #f16f6f;
  animation: glitch010 2.5s infinite;
}

.subtitle {
  height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  /* margin-top: 10px; */
  margin-bottom: 10px;
}

$font: "Roboto", monospace;
$steps: 7;
.subheading {
  font-size: 24px;
  font-weight: 300;
  margin-top: 10px;
  letter-spacing: 5px;

  /* overlap */
  // line-height: 0;
  // margin: 0;

  // animation: glitch000 2.5s infinite;
  
}

.cray {
  outline: none;
  // font-size: 10vw;
  text-align: center;
  font-family: $font;
  // min-height: 10vw;
  // max-width: 80vw;
  // min-width: 10vw;

  &,
  * {
    animation: cray 6s infinite steps($steps);
    display: inline-block;
  }
}

@keyframes cray {
  @for $i from 1 to $steps {
    $per: 100% / $steps * $i;
    $weight: (100,
      200,
      300,
      400,
      100,
      200,
      300);
    $style: (normal,
      italic,
      normal,
      normal);
    $decor: (none,
      none,
      // line-through,
      underline,
      none,
      none,
      none);


    $arrayW: random(7);
    $arrayS: random(4);
    $arrayD: random(6);

    #{$per} {
      font-weight: nth($weight, $arrayW);
      font-style: nth($style, $arrayS);
      text-decoration: nth($decor, $arrayD);
    }
  }
}

// h2:nth-child(2) {
//   color: #67f3da;
//   animation: glitch001 2.5s infinite;
// }

// h2:nth-child(3) {
//   color: #f16f6f;
//   animation: glitch010 2.5s infinite;
// }

@keyframes glitch000 {
  0% {
    transform: none;
    opacity: 1;
  }

  7% {
    transform: skew(-0.5deg, -0.9deg);
    opacity: 0.75;
  }

  10% {
    transform: none;
    opacity: 1;
  }

  27% {
    transform: none;
    opacity: 1;
  }

  30% {
    transform: skew(0.8deg, -0.1deg);
    opacity: 0.75;
  }

  35% {
    transform: none;
    opacity: 1;
  }

  50% {
    transform: none;
    opacity: 1;
  }

  52% {
    transform: none;
    opacity: 1;
  }

  55% {
    transform: skew(-1deg, 0.2deg);
    opacity: 0.75;
  }

  72% {
    transform: none;
    opacity: 1;
  }

  75% {
    transform: skew(0.4deg, 1deg);
    opacity: 0.75;
  }

  80% {
    transform: none;
    opacity: 1;
  }

  100% {
    transform: none;
    opacity: 1;
  }
}

@keyframes glitch001 {
  0% {
    transform: none;
    opacity: 0.25;
  }

  7% {
    transform: translate(-2px, -3px);
    opacity: 0.5;
  }

  10% {
    transform: none;
    opacity: 0.25;
  }

  27% {
    transform: none;
    opacity: 0.25;
  }

  30% {
    transform: translate(-5px, -2px);
    opacity: 0.5;
  }

  35% {
    transform: none;
    opacity: 0.25;
  }

  50% {
    transform: none;
    opacity: 0.25;
  }

  52% {
    transform: none;
    opacity: 0.25;
  }

  55% {
    transform: translate(-5px, -1px);
    opacity: 0.5;
  }

  72% {
    transform: none;
    opacity: 0.25;
  }

  75% {
    transform: translate(-2px, -6px);
    opacity: 0.5;
  }

  80% {
    transform: none;
    opacity: 0.25;
  }

  100% {
    transform: none;
    opacity: 0.25;
  }
}

@keyframes glitch010 {
  0% {
    transform: none;
    opacity: 0.25;
  }

  7% {
    transform: translate(2px, 3px);
    opacity: 0.5;
  }

  10% {
    transform: none;
    opacity: 0.25;
  }

  27% {
    transform: none;
    opacity: 0.25;
  }

  30% {
    transform: translate(5px, 2px);
    opacity: 0.5;
  }

  35% {
    transform: none;
    opacity: 0.25;
  }

  50% {
    transform: none;
    opacity: 0.25;
  }

  52% {
    transform: none;
    opacity: 0.25;
  }

  55% {
    transform: translate(5px, 1px);
    opacity: 0.5;
  }

  72% {
    transform: none;
    opacity: 0.25;
  }

  75% {
    transform: translate(2px, 6px);
    opacity: 0.5;
  }

  80% {
    transform: none;
    opacity: 0.25;
  }

  100% {
    transform: none;
    opacity: 0.25;
  }
}