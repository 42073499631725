.box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background: rgba(0, 0, 0, 1.0);
}

.box-padder {
  position: absolute;
  top: 2%;
  left: 2%;
  bottom: 2%;
  right: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-image {
  max-width:100%;
  max-height:100%;
}

.button {
  background: none;
  border: none;
}

.close-button {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;

  font-size: 30px;
  font-weight: 300;
  color: #fff;
  text-decoration: none;
}

.prev-button {
  position: absolute;
  top: 50%;
  left: 0;
  cursor: pointer;

  font-size: 30px;
  color: #fff;
  text-decoration: none;
}

.next-button {
  position: absolute;
  top: 50%;
  right: 0;
  cursor: pointer;

  font-size: 30px;
  color: #fff;
  text-decoration: none;
}

.overlay {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;

  color: #FFF;
  background: rgba(0, 0, 0, 0.7);

  text-align: left;

  padding-top: 20px;
  padding-bottom: 20px;

  transition: all 0.25s ease;
  opacity: 0;
}

.modal-image:hover + .overlay {
  opacity: 1;
}

.overlay h1 {
  font-size: 14px;
  font-weight: 300;

  margin: 0 0 10px 20px;

  font-family: Roboto;
}

.overlay p {
  margin-left: 20px;
  font-weight: 400;
  font-size: 14px;

  font-family: Roboto;

  margin-bottom: 0;
}
