.container {
  max-width: 900px;
  margin: 40px auto;
  border-style: solid;
  /* border-color: rgba(0, 0, 0, .05); */
  border-color: #ffffff;
  border-width: 2px;

  padding: 20px 20px 0;
}

@media only screen and (max-width: 1024px) {
  .container {
    margin: 0px auto;
    border-width: 0px !important;
    padding: 0;
  }
}

.page-content {
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 56px;
  margin-bottom: 56px;
}
